<template>
<div class="bg-greyblue">
    <div class="container">
        <div class="flex items-center justify-center pt-25px pb-25px bg-white text-black rounded lg:w-2/12">
            <p class="text-med font-semibold">Peer Table</p>
        </div>
        <div class="flex items-center justify-center px-25px py-25px bg-white text-black rounded">
            <div class="peer-table" v-if="extractedData.length > 10">
                <table>
                    <thead>
                        <tr class="table-header rounded">
                            <th style="width:200px">Position</th>
                            <th style="width:220px">Leadership Score</th>
                            <th style="width:220px">Technology Score</th>
                            <th style="width:220px">Growth Score</th>
                            <th style="width:220px">Barriers Score</th>
                            <th style="width:220px">Total Score</th>
                        </tr>
                    </thead>
                    <tbody v-if="this.loaded === true">
                        <tr v-for="(item, index) in filteredData" :key="index" class="table-row" :class="{ 'green-row': item.userPosition }">
                            <td class="table-item">{{ index + 1 }}</td>
                            <td class="table-item">{{ leadership[index] }} </td>
                            <td class="table-item">{{ technology[index] }} </td>
                            <td class="table-item">{{ growth[index] }} </td>
                            <td class="table-item">{{ barriers[index] }} </td>
                            <td class="table-item" name="total">{{ leadership[index] + technology[index] + growth[index] + barriers[index]}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                No data available for the selected sector.
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import store from "../../store";

export default {
    data() {
        return {
            users: [],
            questions: [],
            companies: [],
            multipleResults: '',
            extractedData: [], // Add this line to initialize extractedData
            sectionScores: [],
            leadership: [],
            technology: [],
            growth: [],
            barriers: [],
            loaded: false,
        };
    },
    created() {

        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/questions/get/1',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/1'
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: questions
                }, {
                    data: companies
                }, {
                    data: multipleResults
                }, ]) => {
                    this.questions = questions;
                    this.companies = companies;
                    this.multipleResults = multipleResults;
                    this.getPeerData(this.companies);

                });

        });

    },
    computed: {
        filteredData() {
            return this.extractedData.slice(0, 18);
        }
    },
    methods: {
        getPeerData(companyInfo) {
            const companyResults = companyInfo.results;
            let resultID = null;
            const sectionIdsToFetch = [1, 2, 3, 4];
            const extractedData = [];

            for (let companyResult of companyResults) {

                resultID = companyResult.id;

                if (resultID) {
                    // Fetch data for all specified sections
                    for (let sectionId of sectionIdsToFetch) {
                        axios
                            .get(`https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/peertable/${resultID}/${sectionId}`)
                            .then((response) => {
                                const peerData = response.data[0];

                                const sectionData = peerData.table.flatMap((subsection) => ({
                                    sectionId: subsection.sectionId,
                                    totalScore: subsection.totalScore,
                                    userPosition: subsection.userPosition,
                                }));

                                extractedData.push(...sectionData);
                                this.extractedData = extractedData;
                                this.sectionScores = this.groupScoresBySection(extractedData);
                            })
                            .catch((error) => {
                                console.error(`Error fetching peer data for section ${sectionId}:`, error);
                            });

                    }
                    break;
                }
            }
        },

        groupScoresBySection() {
            const sectionScores = {};
            setTimeout(() => {
                for (let item of this.extractedData) {
                    const sectionId = item.sectionId;
                    const totalScore = item.totalScore;
                    if (sectionScores[sectionId]) {
                        sectionScores[sectionId].push(totalScore);
                    } else {
                        sectionScores[sectionId] = [totalScore];
                    }
                    this.leadership = sectionScores[1];
                    this.technology = sectionScores[2];
                    this.growth = sectionScores[3];
                    this.barriers = sectionScores[4];
                    this.loaded = true;
                }
            }, 2000);
            return sectionScores;
        },

    },

};
</script>

<style>
.peer-table {
    height: 500px;
    overflow: scroll;
}

.table-item {
    width: 260px;
    text-align: center;
    padding: 10px 0;
}

.green-row {
    background-color: #139895!important;
    color: white;
}

.table-header {
    background: black;
    color: white;
    height: 50px;
    border-radius: 10px 10px 0 0;
}

.table-row:nth-child(odd) {
    background-color: #F0F2F4;
}
</style>
