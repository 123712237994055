<template>
<div class="chart-wrapper">
    <canvas ref="chart"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js';

export default {
    props: {
        averageData: {
            type: Array,
            required: true,
        },
        userData: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    mounted() {
        this.renderChart();
    },
    computed: {
        formattedUserData() {
            return this.formatData(this.userData);
        },
        formattedAverageData() {
            return this.formatData(this.averageData);
        },
    },
    methods: {
        renderChart() {
            // Render the chart using Chart.js
            this.chart = new Chart(this.$refs.chart, {
                type: 'radar', // Set chart type to radar for spider chart
                data: {
                    labels: this.formattedUserData.map(item => item.x), // Use the formattedUserData for labels
                    datasets: [{
                            label: 'Average Data',
                            data: this.formattedAverageData.map(item => item.y), // Use the formattedAverageData for data
                            fill: false,
                            backgroundColor: 'rgba(0,0,0)', // Set background color for the dataset
                            borderColor: 'rgba(0,0,0)', // Set border color for the dataset
                            borderWidth: 2, // Set border width for the dataset
                        },
                        {
                            label: 'User Data',
                            data: this.formattedUserData.map(item => item.y), // Use the formattedUserData for data
                            fill: false,
                            backgroundColor: 'rgba(65,172,170)', // Set background color for the dataset
                            borderColor: 'rgba(65,172,170)', // Set border color for the dataset
                            borderWidth: 2, // Set border width for the dataset
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: 'Acid Test', // Set chart title
                        fontSize: 14, // Set font size for the chart title
                        fontStyle: 'normal', // Set font style for the chart title
                        fontColor: 'rgba(0, 0, 0, 1)', // Set font color for the chart title
                        defaultFontFamily: Chart.defaults.global.defaultFontFamily = "'Poppins', 'sans-serif'",
                    },
                    legend: {
                        display: true,
                        labels: {
                            fontColor: 'rgba(0, 0, 0, 1)', // Set font color for the legend labels
                        },
                    },
                    scale: {
                        r: {
                            display: true,
                            beginAtZero: true,
                            max: 10, // Set max value for the scale
                            pointLabels: {
                                fontColor: 'rgba(0, 0, 0, 1)', // Set font color for the point labels
                            },
                            ticks: {
                                fontColor: 'rgba(0, 0, 0, 1)', // Set font color for the scale ticks
                            },
                            grid: {
                                color: 'rgba(0, 0, 0, 0.1)', // Set color for the grid lines
                            },
                        },
                    },
                },
            });
        },

        updateChart() {
            // Update the chart with new data
            this.chart.data.labels = this.userData.map(item => item.year);
            this.chart.data.datasets[0].data = this.formatData(this.averageData);
            this.chart.data.datasets[1].data = this.formatData(this.userData);
            this.chart.update();
        },

        formatData(data) {
            // Format the data to match the chart data format
            return data.map(item => {
                return {
                    x: item.year,
                    y: item.value,
                };
            });
        },
    },
}
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 400px;
}

canvas {
  background: #F0F2F4;
  border-radius: 10px;
  padding: 10px;
}
</style>