  <template>
    <div class="bg-darkgreen relative flex flex-col items-center justify-center text-center bg-white lg:px-12 md:px-12 py-20">
        <div class="container">
            <div class="w-full mr-auto">
                <logo />
            </div>
        </div>
    </div>
</template>

<script>
import logo from '@/assets/svg/logo.svg';

export default {
  components: {
    logo
  },
};
</script>